import React, {useEffect} from 'react';
import {NavigateFunction, Route, Routes, useLocation, useNavigate} from "react-router-dom";

const Container = () => {
  const navigate: NavigateFunction = useNavigate();
  const search: string = useLocation().search;
  const error: string | null = new URLSearchParams(search).get("error");
  const consentId: string | null = new URLSearchParams(search).get("cid");

  // Redirect to / if consentId is blank
  useEffect(() => {
    if (!consentId) {
      navigate('/');
    }
  }, [consentId, navigate]);

  return (
    <div className="container">
      {error == null ? (
        <div className="alert alert-success d-flex align-items-center" role="alert">
          <div className="col-1 d-flex justify-content-center align-items-center">
            <i className="bi bi-check-circle-fill"></i>
          </div>
          <div className="col" style={{textAlign: 'left'}}>
            <h3 className="alert-heading">Success</h3>
            <p>Payment successful for consent ID: {consentId}</p>
          </div>
        </div>
      ) : (
        <div className="alert alert-danger" role="alert">
          <div className="row">
            <div className="col-1 d-flex justify-content-center align-items-center">
              <i className="bi bi-exclamation-circle-fill"></i>
            </div>
            <div className="col" style={{textAlign: 'left'}}>
              <h3 className="alert-heading">Error</h3>
              <p>Payment unsuccessful for consent ID: {consentId}</p>
              <p>{error}</p>
            </div>
          </div>
        </div>
      )}
      <button className="btn btn-primary ml-1 mr-1" onClick={() => navigate('/')}>Back to Shopping Cart</button>
    </div>);
}

export default function Redirect() {
  return (
    <Routes>
      <Route path="/" element={<Container/>}/>
    </Routes>
  );
}
