import React, {Component} from 'react';
import lollipop from '../lollipop.jpg';
import {
  Amount,
  AmountCurrencyEnum,
  AuthFlow,
  AuthFlowDetailTypeEnum,
  ConsentDetailTypeEnum,
  CreateConsentResponse,
  CreateQuickPaymentResponse,
  EnduringConsentRequest,
  GatewayFlow,
  Pcr,
  Period,
  QuickPaymentRequest,
  SingleConsentRequest
} from 'blink-debit-api-client-node';
import {client} from '../blinkDebitClientInstance';
import {AppRedirectFlow} from "../AppRedirectFlow";

interface State {
  errorResponse: any,
  disabled: boolean,
  clickedButton?: string
}

class Cart extends Component<{}, State> {

  constructor(props: {}) {
    super(props);

    this.state = {
      errorResponse: {},
      disabled: false
    }

    this.submitForm = this.submitForm.bind(this);
  }

  handleButtonClick = (value: string): void => {
    this.setState({disabled: false, errorResponse: {}, clickedButton: value});
  }

  async submitForm(e: React.FormEvent): Promise<void> {
    e.preventDefault();

    const buttonValue: string | undefined = this.state.clickedButton;

    this.setState({disabled: true, errorResponse: {}});

    if (buttonValue === "single") {
      const request: QuickPaymentRequest = {
        type: ConsentDetailTypeEnum.Single,
        flow: {
          detail: {
            type: AuthFlowDetailTypeEnum.Gateway,
            redirectUri: `${window.location.origin}/redirect`
          } as GatewayFlow
        } as AuthFlow,
        amount: {
          currency: AmountCurrencyEnum.NZD,
          total: process.env.REACT_APP_BLINKPAY_AMOUNT
        } as Amount,
        pcr: {
          particulars: "lollipop",
          code: "code",
          reference: "reference"
        } as Pcr
      };

      const createQuickPaymentResponse: CreateQuickPaymentResponse = await client.createQuickPayment(request);
      // redirect to gateway
      const redirectUri: string | undefined = createQuickPaymentResponse.redirectUri;
      if (redirectUri !== undefined) {
        // @ts-ignore
        window.location.assign(redirectUri);
      }
    } else if (buttonValue === "asb") {
      const request: QuickPaymentRequest = {
        type: ConsentDetailTypeEnum.Single,
        flow: {
          detail: {
            bank: 'ASB',
            type: AuthFlowDetailTypeEnum.Redirect,
            redirectUri: 'blinkpay://test-app/return',
            redirectToApp: true
          } as AppRedirectFlow
        } as AuthFlow,
        amount: {
          currency: AmountCurrencyEnum.NZD,
          total: process.env.REACT_APP_BLINKPAY_AMOUNT
        } as Amount,
        pcr: {
          particulars: "lollipop",
          code: "code",
          reference: "reference"
        } as Pcr
      };

      const createQuickPaymentResponse: CreateQuickPaymentResponse = await client.createQuickPayment(request);
      // redirect to gateway
      const redirectUri: string | undefined = createQuickPaymentResponse.redirectUri;
      if (redirectUri !== undefined) {
        // @ts-ignore
        window.location.assign(redirectUri);
      }
    } else if (buttonValue === "enduring") {
      const request: EnduringConsentRequest = {
        type: ConsentDetailTypeEnum.Enduring,
        flow: {
          detail: {
            type: AuthFlowDetailTypeEnum.Gateway,
            redirectUri: `${window.location.origin}/pay`
          } as GatewayFlow
        } as AuthFlow,
        maximumAmountPeriod: {
          currency: AmountCurrencyEnum.NZD,
          total: process.env.REACT_APP_BLINKPAY_AMOUNT
        } as Amount,
        fromTimestamp: new Date(),
        period: Period.Fortnightly
      };

      const createConsentResponse: CreateConsentResponse = await client.createEnduringConsent(request);
      // redirect to gateway
      const redirectUri: string | undefined = createConsentResponse.redirectUri;
      if (redirectUri !== undefined) {
        // @ts-ignore
        window.location.assign(redirectUri);
      }
    } else if (buttonValue === "xero") {
      // @ts-ignore
      const request: SingleConsentRequest = {
        type: ConsentDetailTypeEnum.Single,
        flow: {
          detail: {
            type: AuthFlowDetailTypeEnum.Gateway,
            redirectUri: `${window.location.origin}/redirect`
          } as GatewayFlow
        } as AuthFlow,
        amount: {
          currency: AmountCurrencyEnum.NZD,
          total: process.env.REACT_APP_BLINKPAY_AMOUNT
        } as Amount,
        pcr: {
          particulars: "lollipop",
          code: "code",
          reference: "reference"
        } as Pcr
      };

      const createConsentResponse: CreateConsentResponse = await client.createSingleConsent(request);
      // redirect to gateway
      const redirectUri: string | undefined = createConsentResponse.redirectUri;
      if (redirectUri !== undefined) {
        // @ts-ignore
        window.location.assign(redirectUri + "&source=xero");
      }
    }
  }

  render() {
    return (
      <div className="container">
        <h3>Shopping Cart</h3>
        <form onSubmit={this.submitForm} className="form">
          <table className="table">
            <tbody>
            <tr>
              <td className="border">
                <img src={lollipop as string} alt="lollipop" width="200"/>
              </td>
              <td className="border">
                Red Heart Lollipop, unwrapped
              </td>
              <td className="border">
                ${process.env.REACT_APP_BLINKPAY_AMOUNT}
              </td>
            </tr>
            </tbody>
          </table>
          <br/>
          <div className="form-group form-row align-items-center justify-content-center">
            <button type="submit" className="btn btn-primary" onClick={() => this.handleButtonClick('single')}
                    disabled={this.state.disabled}>PayNow
            </button>
            &nbsp;
            <button type="submit" className="btn btn-primary" onClick={() => this.handleButtonClick('asb')}
                    disabled={this.state.disabled}>PayNow with ASB
            </button>
            &nbsp;
            <button type="submit" className="btn btn-primary" onClick={() => this.handleButtonClick('enduring')}
                    disabled={this.state.disabled}>AutoPay
            </button>
            &nbsp;
            <button type="submit" className="btn btn-primary" onClick={() => this.handleButtonClick('xero')}
                    disabled={this.state.disabled}>Invoice
            </button>
          </div>
        </form>
      </div>);
  }
}

export default Cart;
