import React, {useEffect, useState} from 'react';
import {NavigateFunction, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {client} from "../blinkDebitClientInstance";
import {
  Amount,
  AmountCurrencyEnum,
  Consent,
  ConsentStatusEnum,
  EnduringPaymentRequest,
  PaymentRequest, PaymentResponse, Pcr
} from "blink-debit-api-client-node";

const Container = () => {
  const navigate: NavigateFunction = useNavigate();
  const search: string = useLocation().search;
  const error: string | null = new URLSearchParams(search).get("error");
  const consentId: string | null = new URLSearchParams(search).get("cid");
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      if (error == null && consentId) {
        try {
          setIsLoading(true);
          const enduringConsent: Consent = await client.getEnduringConsent(consentId);
          if (enduringConsent.status === ConsentStatusEnum.Authorised) {
            const amount: number = process.env.REACT_APP_BLINKPAY_AMOUNT ? parseFloat(process.env.REACT_APP_BLINKPAY_AMOUNT) / 4 : 0.01;
            const total : string = amount.toFixed(2);
            const paymentRequest: PaymentRequest = {
              consentId: consentId,
              enduringPayment: {
                amount: {
                  currency: AmountCurrencyEnum.NZD,
                  total: total
                } as Amount,
                pcr: {
                  code: "code",
                  particulars: "lollipop",
                  reference: "1 of 4"
                } as Pcr
              } as EnduringPaymentRequest
            };
            const paymentResponse: PaymentResponse = await client.createPayment(paymentRequest);
            if (paymentResponse.paymentId) {
              setPaymentId(paymentResponse.paymentId);
              setApiError(null);
            }
          }
        } catch (error: any) {
          console.error("API call failed:", error);
          setApiError(error.message || "An unknown error occurred");
        } finally {
          setIsLoading(false);
        }
      } else {
        setApiError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [error, consentId]);

  useEffect((): void => {
    if (!consentId) {
      navigate('/');
    }
  }, [consentId, navigate, apiError]);

  if (isLoading) {
    return <div className="container">Loading...</div>;
  }

  return (
    <div className="container">
      {apiError == null ? (
        <div className="alert alert-success d-flex align-items-center" role="alert">
          <div className="col-1 d-flex justify-content-center align-items-center">
            <i className="bi bi-check-circle-fill"></i>
          </div>
          <div className="col" style={{textAlign: 'left'}}>
            <h3 className="alert-heading">Success</h3>
            <p>Payment successful for consent ID: {consentId}</p>
            <p>Payment ID: {paymentId}</p>
          </div>
        </div>
      ) : (
        <div className="alert alert-danger" role="alert">
          <div className="row">
            <div className="col-1 d-flex justify-content-center align-items-center">
              <i className="bi bi-exclamation-circle-fill"></i>
            </div>
            <div className="col" style={{textAlign: 'left'}}>
              <h3 className="alert-heading">Error</h3>
              <p>Payment unsuccessful for consent ID: {consentId}</p>
              <p>{apiError}</p>
            </div>
          </div>
        </div>
      )}
      <button className="btn btn-primary ml-1 mr-1" onClick={() => navigate('/')}>Back to Shopping Cart</button>
    </div>
  );
}

export default function Pay() {
  return (
    <Routes>
      <Route path="/" element={<Container/>}/>
    </Routes>
  );
}
